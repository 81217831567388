var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";
import useFavoriteProductsState from "./useFavoriteProductsState";
const useFavoriteProducts = () => {
  const initialState = window.favoriteProducts || [];
  const { getFavoriteProducts, addProductKey, removeProductKey } = useFavoriteProductsState(initialState);
  const getWretch = (url) => wretch(url).addon(QueryStringAddon);
  const addToFavorite = (idProduct, idProductAttribute, refreshList = 0) => __async(void 0, null, function* () {
    return new Promise((resolve, reject) => {
      getWretch(window.addToFavoriteAction).query({
        id_product: idProduct,
        id_product_attribute: idProductAttribute,
        refresh_list: refreshList
      }).post().json((data) => {
        if (data.success) {
          addProductKey(`${idProduct}_${idProductAttribute}`);
        }
        resolve(data);
      }).catch(() => {
        reject(Error("Something went wrong"));
      });
    });
  });
  const removeFromFavorite = (idProduct, idProductAttribute, refreshList = 0) => __async(void 0, null, function* () {
    return new Promise((resolve, reject) => {
      getWretch(window.removeFromFavoriteAction).query({
        id_product: idProduct,
        id_product_attribute: idProductAttribute,
        refresh_list: refreshList
      }).post().json((data) => {
        if (data.success) {
          removeProductKey(`${idProduct}_${idProductAttribute}`);
        }
        resolve(data);
      }).catch(() => {
        reject(Error("Something went wrong"));
      });
    });
  });
  return {
    getFavoriteProducts,
    addToFavorite,
    removeFromFavorite
  };
};
export default useFavoriteProducts;
