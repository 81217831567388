import $ from "jquery";
export default class TopMenu {
  constructor(el) {
    this.$el = $(el);
  }
  init() {
    const self = this;
    self.$el.hoverIntent({
      over: self.addClassSubMenu,
      out: self.removeClassSubMenu,
      selector: " > li",
      timeout: 300
    });
  }
  addClassSubMenu() {
    const $item = $(this);
    let expanded = $item.attr("aria-expanded");
    if (typeof expanded !== "undefined") {
      $(".main-menu__item--active").removeClass("main-menu__item--active").attr("aria-expanded", false).find(".main-menu__sub").attr("aria-expanded", false).attr("aria-hidden", true);
      expanded = expanded.toLowerCase() === "true";
      $item.addClass("main-menu__item--active").attr("aria-expanded", true);
      $(".main-menu__sub", $item).attr("aria-expanded", true).attr("aria-hidden", false);
    }
  }
  removeClassSubMenu() {
    const $item = $(this);
    let expanded = $item.attr("aria-expanded");
    if (typeof expanded !== "undefined") {
      $item.removeClass("main-menu__item--active").attr("aria-expanded", false);
      $(".main-menu__sub", $item).attr("aria-expanded", false).attr("aria-hidden", true);
    }
  }
}
