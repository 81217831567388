import $ from "jquery";
import prestashop from "prestashop";
$(() => {
  const productConfig = (qv) => {
    $(".js-thumb").on("click", (event) => {
      if ($(".js-thumb").hasClass("selected")) {
        $(".js-thumb").removeClass("selected");
      }
      $(event.currentTarget).addClass("selected");
      $(".js-qv-product-cover").attr("src", $(event.target).data("image-large-src"));
    });
    qv.find("#quantity_wanted").TouchSpin({
      verticalupclass: "material-icons touchspin-up",
      verticaldownclass: "material-icons touchspin-down",
      buttondown_class: "btn btn-touchspin js-touchspin",
      buttonup_class: "btn btn-touchspin js-touchspin",
      min: 1,
      max: 1e6
    });
  };
  prestashop.on("clickQuickView", (elm) => {
    const data = {
      action: "quickview",
      id_product: elm.dataset.idProduct,
      id_product_attribute: elm.dataset.idProductAttribute
    };
    $.post(prestashop.urls.pages.product, data, null, "json").then((resp) => {
      $("body").append(resp.quickview_html);
      const productModal = $(
        `#quickview-modal-${resp.product.id}-${resp.product.id_product_attribute}`
      );
      productModal.modal("show");
      productConfig(productModal);
      productModal.on("hidden.bs.modal", () => {
        productModal.remove();
      });
    }).fail((resp) => {
      prestashop.emit("handleError", {
        eventType: "clickQuickView",
        resp
      });
    });
  });
});
